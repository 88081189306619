import React from 'react';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleLeft } from '@fortawesome/free-solid-svg-icons';
import './index.css';

function HelpAndSupport() {
    const navigate = useNavigate();

    return (
        <div className='help-wrap'>
            <div className='mt-6 flex flex-wrap flex-row items-center justify-start gap-4 bread-nav' onClick={() => navigate('/profile')}>
                <FontAwesomeIcon icon={faAngleLeft} />
                <span>Back</span>
            </div>
            <h2 className='mb-4' style={{ fontSize: '1.2rem', fontWeight: 'bold', color: '#0066F6' }}>Help and Support</h2>
            <p style={{ fontSize: '0.9rem' }}>
                Welcome to the Help & Support section of MCOINS. Our goal is to ensure you have the best possible trading experience. If you need assistance, you'll find a variety of resources below to help you resolve any issues or answer any questions.
            </p>

            <p className='mt-3 mb-3' style={{ fontWeight: 'bold', fontSize: '1.1rem' }}>Frequently Asked Questions (FAQs)</p>

            <div className='mb-4' style={{ fontSize: '0.9rem' }}>
                <strong>1. How do I create an account?</strong><br />
                To create an account, download the app and follow the registration process. You'll need to provide your phone number, and create a secure Mpin. You may also need to verify your identity by uploading relevant documents.
            </div>

            <div className='mb-4' style={{ fontSize: '0.9rem' }}>
                <strong>2. How can I reset my password?</strong><br />
                If you've forgotten your password, go to the login screen and click on "Forgot Password." You'll receive an email with instructions on how to reset it.
            </div>

            <div className='mb-4' style={{ fontSize: '0.9rem' }}>
                <strong>3. What types of trades can I make?</strong><br />
                Our platform allows you to trade a variety of financial instruments, including stocks, cryptocurrencies, commodities, and more. Please review the available markets within the app.
            </div>

            <div className='mb-4' style={{ fontSize: '0.9rem' }}>
                <strong>4. How can I deposit or withdraw funds?</strong><br />
                Go to the "Portfolio" section of the app, select "Top Up" or "Withdraw," and follow the instructions. You can use bank transfers, debit/credit cards, or other supported payment methods.
            </div>

            <div className='mb-4' style={{ fontSize: '0.9rem' }}>
                <strong>5. Are there any fees?</strong><br />
                Fees may apply for specific actions such as trading, withdrawals, and other account activities. You can view the complete fee structure within the app under "Account" &gt; "Fees."
            </div>

            <h3 className='mt-3 mb-4' style={{ fontWeight: 'bold', fontSize: '1.1rem' }}>Contact Support</h3>
            <p style={{ fontSize: '0.9rem' }}>
                If you need further assistance, please feel free to contact us:
            </p>
            <ul>
                <li>Email Support: <a href="mailto:help.mcoins@protonmail.com">help.mcoins@protonmail.com</a></li>
                <li>Live Chat: Available 24/7 within the app (click on the "Support" button).</li>
                <li>Business Hours: Monday - Friday, 9 AM - 6 PM (local time)</li>
            </ul>

            <h3 className='mt-3 mb-4' style={{ fontWeight: 'bold', fontSize: '1.1rem' }}>Troubleshooting Guide:</h3>
            <ul>
                <li className='mb-3'><strong>Issue with Account Login:</strong>
                    <ul style={{ fontSize: '0.9rem' }}>
                        <li>Ensure your mobile number and MPIN are entered correctly.</li>
                        <li>Check your internet connection.</li>
                        <li>If you cannot log in, reset your password using the "Forgot Password" link.</li>
                    </ul>
                </li>
                <li className='mb-3'><strong>Issue with Withdrawing Funds:</strong>
                    <ul style={{ fontSize: '0.9rem' }}>
                        <li>Ensure you have sufficient funds in your account.</li>
                        <li>Check if all trading windows are available.</li>
                        <li>Try restarting the app or clearing the app's cache.</li>
                    </ul>
                </li>
            </ul>

            <h3 className='mt-3 mb-4' style={{ fontWeight: 'bold', fontSize: '1.1rem' }}>Report a Problem</h3>
            <p style={{ fontSize: '0.9rem' }}>
                If you're experiencing a technical issue or have a specific inquiry, please submit a support ticket by emailing us at <a href="mailto:help.mcoins@protonmail.com">help.mcoins@protonmail.com</a>.
            </p>
            <p style={{ fontSize: '0.9rem' }}>
                Include the following details to help us resolve your issue faster:
            </p>
            <ul style={{ fontSize: '0.9rem' }}>
                <li>Your username or account email.</li>
                <li>A detailed description of the issue.</li>
                <li>Screenshots or error messages (if applicable).</li>
            </ul>

            <h3 className='mt-3 mb-4' style={{ fontWeight: 'bold', fontSize: '1.1rem' }}>Community and Resources</h3>
            <ul style={{ fontSize: '0.9rem' }}>
                <li><strong>MCOINS Community Forum:</strong> Connect with other users, share tips, and ask questions. [Insert Forum Link]</li>
                <li><strong>Educational Resources:</strong> Access trading guides, tutorials, and market analysis to enhance your trading skills. [Insert Resource Link]</li>
            </ul>
        </div>
    );
}

export default HelpAndSupport;
