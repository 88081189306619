import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import './Dashboard.css';
import Spinner from '../Loader/Spinner';

const Dashboard = ({ fullName, token, API_URL }) => {
    const [loading, setLoading] = useState(true);
    const truncatedName = fullName.length > 10 ? `${fullName.slice(0, 10)}` : fullName;
    const navigate = useNavigate();

    const [windowHeight, setWindowHeight] = useState(window.innerHeight);

    useEffect(() => {
        const handleResize = () => {
            setWindowHeight(window.innerHeight);
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    useEffect(() => {
        if (!token) {
            navigate('/login');
        }
    }, [token]);

    useEffect(() => {
        setTimeout(() => {
            setLoading(false);
        }, 3000);
    }, [loading])

    const handleNavigate = () => {
        setTimeout(() => {
            setLoading(false);
            navigate('/our-plans');
        }, 3000);
    };

    return (
        <div className='dash' style={{ backgroundColor: loading ? '#ffffff' : '#7792BF', paddingTop: windowHeight < 700 ? '5px' : '2rem' }}>
            <div className='dash-container'>
                {loading ? (
                    <Spinner />
                ) : (
                    <>
                        <h3 className="text-center" style={{ fontSize: '1.5rem', marginBottom: window.innerHeight < 700 ? '5px' : '1rem' }}>Welcome {truncatedName},</h3>
                        <div onClick={handleNavigate} className='dash-img'>
                            <img src="/home_page_main.png" alt="" />
                        </div>
                    </>
                )}
            </div>
        </div>
    );
};

export default Dashboard;
