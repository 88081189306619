import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import InputGroup from 'react-bootstrap/InputGroup';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Swal from 'sweetalert2';
import axios from 'axios';
import qs from 'qs';

const CompletePayment = ({ API_URL, token }) => {
    const { id, amount } = useParams();
    const navigate = useNavigate();
    const [breakPaymentAmount, setBreakPaymentAmount] = useState('');
    const [isLoading, setIsLoding] = useState(false);
    const [plan, setPlan] = useState(null);
    const [copied, setCopied] = useState(false); // State to handle "Link Copied" message
    const [currentImageIndex, setCurrentImageIndex] = useState(0);
    const [countdown, setCountdown] = useState(120);
    const [transactionId, setTransactionId] = useState('');


    const upiId = ['indigenouspeoplesdevorg@sbi'];

    const fetchData = async () => {
        setIsLoding(true);
        try {
            const config = {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            };

            const [plansResponse] = await Promise.all([
                axios.get(`${API_URL}/api/plan/payment-details?breakPaymentId=${id}`, config),
            ]);

            if (plansResponse.status === 200 && plansResponse.data.plan) {
                setPlan(plansResponse.data.plan);
            }
        } catch (error) {
            console.error('Error fetching data:', error);
        } finally {
            setIsLoding(false);
        }
    };

    useEffect(() => {
        fetchData();
    }, [API_URL, token]);

    const startTimer = () => {
        const interval = setInterval(() => {
            setCountdown((prevCountdown) => {
                if (prevCountdown === 1) {
                    setCurrentImageIndex((prevIndex) => (prevIndex + 1));
                    return 120;
                }
                return prevCountdown - 1;
            });
        }, 1000);

        return () => clearInterval(interval);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (!breakPaymentAmount || !transactionId) {
            Swal.fire({
                icon: 'error',
                title: 'Invalid input',
                text: `Either break amount or transaction ID are empty.`,
                confirmButtonColor: '#F7931A'
            });
            return;
        }

        try {
            const data = {
                breakPaymentId: id,
                breakPaymentAmount,
                transactionId
            };

            const config = {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            };

            const res = await axios.post(`${API_URL}/api/plan/complete-payment`,
                qs.stringify(data),
                config
            );

            if (res.status === 200) {
                setIsLoding(false);
            }

            Swal.fire({
                icon: 'success',
                title: 'Break amount updation request raised.',
                text: 'Plan activation can take 24hrs to 48hrs upon successful payment validation. If your Plan is not activated in a given time, kindly reach us with UPI transaction ID on our helpdesk email id.',
                confirmButtonColor: '#F7931A'
            }).then(() => {
                let timerInterval;

                // Show loader with a countdown timer for 4 seconds
                Swal.fire({
                    title: 'Redirecting...',
                    html: 'Redirecting in <b></b> seconds.',
                    timer: 4000,
                    timerProgressBar: true,
                    showConfirmButton: false,
                    allowOutsideClick: false,
                    willOpen: () => {
                        Swal.showLoading();
                        const b = Swal.getHtmlContainer().querySelector('b');
                        timerInterval = setInterval(() => {
                            b.textContent = Math.ceil(Swal.getTimerLeft() / 1000);
                        }, 100);
                    },
                    willClose: () => {
                        clearInterval(timerInterval);
                    }
                }).then(() => {
                    if (res.status === 200) {
                        navigate(`/portfolio`);
                    } else {
                        navigate('/');
                    }
                });
            });


        } catch (err) {
            console.log(err);

            setIsLoding(false);
            Swal.fire({
                icon: 'error',
                title: 'Error',
                text: err?.response?.data?.message || 'Something went wrong. Please try again!',
                confirmButtonColor: '#F7931A'
            });
        }
    }

    const handleCopyCode = () => {
        navigator.clipboard.writeText(upiId).then(() => {
            setCopied(true); // Set copied state to true
            setTimeout(() => {
                setCopied(false); // Hide "Link Copied" message after 2 seconds
            }, 2000);
        }).catch(err => {
            console.error('Failed to copy text: ', err);
            // setPurchaseDetails(false);
        });
        // setPurchaseDetails(true);
        startTimer();
    };

    const formatCurrency = (value) => {
        const numValue = Number(value); // Convert value to a number
        if (isNaN(numValue)) {
            return '0.00'; // Fallback if value is not a number
        }
        return numValue.toLocaleString('en-IN', { minimumFractionDigits: 2 });
    };

    return (
        <div className='refer-container'>
            <div className='pl-4 mt-16'>
                <div className='text-center' style={{ fontSize: '0.9rem', color: '#333' }}>
                    <span>Paid amount : </span>
                    <span>{formatCurrency(plan?.breakPaymentAmount[0] || 0)}</span>
                </div>
                <div className='mt-4'>
                    <span style={{ color: '#343A40' }}>UPI ID</span>
                    <InputGroup className="mb-3 payment-wrap mt-2">
                        <Form.Control
                            placeholder="UPI ID"
                            aria-label="UPI ID"
                            aria-describedby="basic-addon2"
                            disabled
                            value={upiId}
                            className=''
                        />
                        <span onClick={handleCopyCode}>
                            <img src="/copy.png" alt="" />
                        </span>
                        {/* <Button variant="outline-secondary" id="button-addon2" onClick={handleCopyCode}>
                                        Copy Code
                                    </Button> */}
                    </InputGroup>
                    {copied && <span style={{ color: '#F7931A' }}>UPI ID Copied!</span>} {/* Display "Link Copied" message */}
                </div>
                <div className='mt-8'>
                    <span style={{ color: '#343A40' }}>Enter break amount</span>
                    <InputGroup className="mb-3 payment-wrap mt-2">
                        <Form.Control
                            placeholder="Break amount"
                            aria-label="Break amount"
                            aria-describedby="basic-addon2"
                            name='breakPaymentAmount'
                            value={breakPaymentAmount}
                            className=''
                            onChange={(e) => setBreakPaymentAmount(e.target.value)}
                        />
                    </InputGroup>
                </div>
                <div className='mt-4'>
                    <span style={{ color: '#343A40' }}>Enter Transaction ID</span>
                    <InputGroup className="mb-5 payment-wrap mt-2">
                        <Form.Control
                            placeholder="Transaction ID"
                            aria-label="Transaction ID"
                            aria-describedby="basic-addon2"
                            name='transactionId'
                            value={transactionId}
                            className=''
                            onChange={(e) => setTransactionId(e.target.value)}
                        />
                    </InputGroup>
                </div>
            </div>
            <div className='mt-4 pl-4'>
                <InputGroup className="mb-3 mt-2 share" onClick={handleSubmit} style={{ backgroundColor: breakPaymentAmount ? '#0063F5' : '#D3D3D3', width: '290px' }}>
                    <Button variant="outline-secondary" id="button-addon2">
                        Complete Payment
                    </Button>
                </InputGroup>
            </div>
        </div>
    )
}

export default CompletePayment;