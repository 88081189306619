import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleLeft } from '@fortawesome/free-solid-svg-icons';
import Spinner from '../Loader/Spinner';
import './index.css';

function ReferralDetails({ API_URL, token }) {
    const [isLoading, setIsLoading] = useState(false);
    const [data, setData] = useState(null);
    const navigate = useNavigate();

    useEffect(() => {
        const fetchData = async () => {
            setIsLoading(true);
            try {
                const config = {
                    headers: {
                        Authorization: `Bearer ${token}`,
                        'Content-Type': 'multipart/form-data',
                    },
                };

                const response = await axios.get(`${API_URL}/api/plan/referral-details`, config);

                if (response.status === 200) {
                    setData(response?.data);
                }
            } catch (error) {
                console.error(error);
                setIsLoading(false);
            } finally {
                setIsLoading(false);
            }
        };

        fetchData();
    }, [token]);

    const handleNav = () => {
        navigate('/portfolio');
    };

    if (isLoading) {
        return <Spinner />;
    }

    return (
        <div className='ref-details-wrapper'>
            <div className='mt-6 flex flex-wrap flex-row items-center justify-start gap-4 bread-nav' onClick={handleNav}>
                <FontAwesomeIcon icon={faAngleLeft} />
                <span>Back</span>
            </div>

            <div className='mb-24'>
                {/* Section for referred users with their first purchased plans */}
                {data?.referredUserWithFirstPlan?.length > 0 && (
                    data.referredUserWithFirstPlan.map(plan => (
                        <div className='flex flex-wrap flex-row items-center justify-between asset-wrap' key={plan._id}>
                            <div className='flex flex-wrap flex-col gap-3'>
                                <span className='font-medium' style={{ fontSize: '1.1rem' }}>Mobile No.</span>
                                <span style={{ fontSize: '0.9rem' }}>{plan.userMobile}</span>
                            </div>
                            <div>
                                {plan.status === 'pending' ? (
                                    <img src='/pending.gif' alt='Pending' />
                                ) : (
                                    plan.name
                                )}
                            </div>
                        </div>
                    ))
                )}

                {/* Section for referred users without any purchased plans */}
                {data?.referredUsersWithoutPlans?.length > 0 && (
                    data.referredUsersWithoutPlans.map(user => (
                        <div className='flex flex-wrap flex-row items-center justify-between asset-wrap' key={user._id}>
                            <div className='flex flex-wrap flex-col gap-3'>
                                <span className='font-medium' style={{ fontSize: '1.1rem' }}>Mobile No.</span>
                                <span style={{ fontSize: '0.9rem' }}>{user.mobile}</span>
                            </div>
                            <div>
                                <span className='status-indicator'>No plan purchased</span>
                            </div>
                        </div>
                    ))
                )}
                {
                    (data?.referredUsersWithoutPlans?.length === 0) && (data?.referredUserWithFirstPlan?.length === 0) && (
                        <div className='text-center mt-24'>
                            No result found.
                        </div>
                    )
                }
            </div>
        </div>
    );
}

export default ReferralDetails;
