import React from 'react';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleLeft } from '@fortawesome/free-solid-svg-icons';
import './index.css';

function TermAndConditions() {
    const navigate = useNavigate();

    return (
        <div className='help-wrap'>
            <div className='mt-6 flex flex-wrap flex-row items-center justify-start gap-4 bread-nav' onClick={() => navigate('/profile')}>
                <FontAwesomeIcon icon={faAngleLeft} />
                <span>Back</span>
            </div>
            <h2 className='mb-4' style={{ fontSize: '1.2rem', fontWeight: 'bold', color: '#0066F6' }}>Terms & Conditions</h2>
            <div>
                <h3 className='mb-6' style={{ fontSize: '0.9rem', fontWeight: 'bold' }}>By using MCOINS, you agree to these Terms and Conditions:</h3>
                <p className='mb-5' style={{ fontSize: '0.7rem' }}>
                    You must be at least 18 years old and legally capable of entering into contracts. The Platform provides trading services, and you are not responsible for your trades and financial decisions. We may charge fees, which will be disclosed before any transaction. You agree to keep your account information secure and notify us of any unauthorized access. We reserve the right to suspend or terminate accounts for violations. You agree to notify  MCOINS for any claims or damages arising from your use of the service. We may update these Terms at any time, and your continued use signifies acceptance.
                </p>
                <h3 className='mb-6' style={{ fontSize: '0.9rem', fontWeight: 'bold' }}>Account Suspension and Termination</h3>
                <p style={{ fontSize: '0.7rem' }}>
                    We reserve the right to suspend or terminate your account at our discretion, including if:
                    You violate any of these Terms.
                    We are required to do so by law or regulatory authorities.
                    There is suspicious activity or a security threat associated with your account.
                    Upon termination, you will remain responsible for any outstanding fees or obligations, and we may withhold any funds in your account until all obligations are satisfied.
                    Disputes will be resolved under the laws of Puducherry Jurisdictions.
                </p>
            </div>
        </div >
    );
}

export default TermAndConditions;
