import React, { useState, useEffect } from 'react';
import './Loader.css';

const PlanLoader = () => {
    const [currentImage, setCurrentImage] = useState(0);
    const images = ["Loader1.png", "Loader2.png"];

    useEffect(() => {
        const interval = setInterval(() => {
            setCurrentImage((prevImage) => (prevImage + 1) % images.length);
        }, 1000);

        return () => clearInterval(interval);
    }, [images.length]);

    return (
        <div className='loader-wrap gap-24'>
            <div className='loader-item-0 gap-8'>
                <div className='loader-image'>
                    <img src={images[currentImage]} alt="Loader" />
                </div>
                <div className='item-0-text'>
                    Let our Algorithm choose the best plans for you
                </div>
            </div>
            <div className='loader-item-1'>
                India's Highly Rated Cryptocurrency Exchange
            </div>
        </div>
    );
};

export default PlanLoader;
