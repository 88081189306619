import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { LineChart, Line, Tooltip, CartesianGrid, ResponsiveContainer } from 'recharts';
import { useNavigate } from 'react-router-dom';
import './Market.css';
import Loader from '../Loader';
import Spinner from '../Loader/Spinner';
import PlanLoader from '../Loader/PlanLoader';

const Market = ({ token, API_URL }) => {
    const [loading, setLoading] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [assets, setAssets] = useState([]);
    const navigate = useNavigate();

    useEffect(() => {
        const fetchData = async () => {
            try {
                const config = {
                    headers: {
                        Authorization: `Bearer ${token}`,
                        'Content-Type': 'multipart/form-data',
                    },
                };

                const response = await axios.get(`${API_URL}/api/dashboard/get-assets`, config);
                if (response.status === 200) {
                    const updatedAssets = response.data.data.map(asset => ({
                        id: asset.id,
                        name: asset.name,
                        symbol: asset.symbol,
                        priceUsd: asset.priceUsd,
                        changePercent24Hr: parseFloat(asset.changePercent24Hr),
                        volumeUsd24Hr: parseFloat(asset.volumeUsd24Hr),
                        historicalData: [
                            { time: 'Day 1', changePercent24Hr: parseFloat(asset.changePercent24Hr) },
                            { time: 'Day 2', changePercent24Hr: parseFloat(asset.changePercent24Hr * 1.1) },
                            { time: 'Day 3', changePercent24Hr: parseFloat(asset.changePercent24Hr * 0.9) },
                            { time: 'Day 4', changePercent24Hr: parseFloat(asset.changePercent24Hr * 1.2) },
                            { time: 'Day 5', changePercent24Hr: parseFloat(asset.changePercent24Hr * 0.8) },
                        ]
                    }));
                    setAssets(updatedAssets);
                    setIsLoading(false);
                }
            } catch (error) {
                console.error(error); // Log error for debugging
                setIsLoading(false);
            }
        };

        fetchData();
    }, [token]);

    return (
        <div className='dashboard-container mt-8'>
            {
                loading ? (
                    <div>
                        <PlanLoader />
                    </div>
                ) : (
                    <>
                        <div className='mt-8 mb-4 tc'>Market</div>
                        {
                            isLoading ? (
                                <div>
                                    <Spinner />
                                </div>
                            ) : (
                                <div className='mb-24'>
                                    {assets.map(asset => (
                                        <div className='flex flex-wrap flex-row items-center justify-between asset-wrap' key={asset.id}>
                                            <div className='mc-logo-name flex flex-wrap flex-row items-center'>
                                                <div className='mc-logo'>
                                                    <img
                                                        src={`/${asset.symbol}.png`}
                                                        alt={asset.symbol}
                                                        onError={(e) => { e.target.onerror = null; e.target.src = "/CRH.png"; }}
                                                    />
                                                </div>
                                                <div className='pl-4'>
                                                    <div>{asset.name.length > 10 ? `${asset.name.slice(0, 8)}` : asset.name}</div>
                                                    <div>{asset.symbol}</div>
                                                </div>
                                            </div>
                                            <div style={{ width: 60, height: 40 }}>
                                                <ResponsiveContainer>
                                                    <LineChart data={asset.historicalData}> {/* Use each asset's historical data */}
                                                        <CartesianGrid stroke="#eee" strokeDasharray="5 5" />
                                                        <Tooltip />
                                                        {/* Lines showing changePercent24Hr and volumeUsd24Hr */}
                                                        <Line type="monotone" dataKey="changePercent24Hr" stroke="#8884d8" />
                                                    </LineChart>
                                                </ResponsiveContainer>
                                            </div>
                                            <div className='mc-graph-price flex flex-wrap flex-row'>
                                                <div className='pl-4'>
                                                    <div>{Number(asset.priceUsd).toFixed(2)}</div>
                                                    <div>{Number(asset.changePercent24Hr).toFixed(2)}%</div>
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            )
                        }
                    </>
                )
            }
        </div>
    );
};

export default Market;