import axios from 'axios';

const uploadImageToCloudinary = async (file) => {
    const formData = new FormData();
    formData.append('file', file);
    formData.append('upload_preset', 'image_upload'); // Cloudinary upload preset

    try {
        const response = await axios.post(
            `https://api.cloudinary.com/v1_1/dgeum9dnj/image/upload`,
            formData
        );
        return response.data.secure_url; // Return the secure URL of the uploaded image
    } catch (error) {
        console.error('Error uploading to Cloudinary:', error);
        throw error;
    }
};


export {
    uploadImageToCloudinary
}
