import React from 'react';
import Modal from 'react-bootstrap/Modal';
import './Popup.css';

function Popup(props) {
    return (
        <Modal
            {...props}
            size="xl"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            fullscreen
            className="popup-modal"
        >
            <Modal.Header closeButton className="popup-header">
                <img
                    src="/close.png"
                    alt="Close"
                    onClick={props.onHide}
                    className="custom-close-icon"
                />
            </Modal.Header>
            <Modal.Body className="popup-body">
                {props.child}
            </Modal.Body>
        </Modal>
    );
}

export default Popup;
