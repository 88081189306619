import React from 'react';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleLeft } from '@fortawesome/free-solid-svg-icons';
import './index.css';

function KnowAboutReferral(params) {
    const navigate = useNavigate();

    const handleNav = () => {
        navigate('/refer-n-earn');
    };

    return (
        <div className="know-wrapper">
            <div className="mt-6 flex flex-wrap flex-row items-center justify-start gap-4 bread-nav" onClick={handleNav}>
                <FontAwesomeIcon icon={faAngleLeft} />
                <span>Back</span>
            </div>
            <div>
                <div style={{ color: '#0066F6', fontSize: '0.9rem' }} className='mb-6'>
                    Referral Program Terms & Conditions
                </div>
                <div>
                    <p style={{ color: '#6C757D', fontSize: '0.75rem' }} className='mb-4'>
                        By participating in the referral program ("Referral Program") offered by MCOINS, you ("Person A")
                        agree to the following terms and conditions. This program rewards Person A for referring new
                        clients based on the investment comparison between Person A and Person B. The reward is calculated
                        based on Person A's and Person B's individual investments.
                    </p>

                    <h4 style={{ color: '#6C757D', fontSize: '0.8rem' }} className='mb-1 font-semibold'>1. Eligibility</h4>
                    <ul style={{ color: '#6C757D', fontSize: '0.75rem' }} className='mb-4'>
                        <li className='mb-2'>The Referral Program is open to individuals who are registered clients of MCOINS.</li>
                        <li className='mb-2'>
                            To refer a new client (Person B), Person A must provide a unique referral link or code to
                            Person B.
                        </li>
                        <li className='mb-2'>
                            Person B must be a new customer who has never previously registered or participated in MCOINS's
                            services.
                        </li>
                    </ul>

                    <h4 style={{ color: '#6C757D', fontSize: '0.8rem' }} className='mb-1 font-semibold'>2. Referral Reward Structure</h4>
                    <p style={{ color: '#6C757D', fontSize: '0.75rem' }} className='mb-2'>Person A's Referral Reward will be based on a comparison of Person A's investment to Person B's investment. The reward structure is as follows:</p>
                    <ul style={{ color: '#6C757D', fontSize: '0.75rem' }} className='mb-4'>
                        <li className='mb-2'>
                            <strong>5% Referral Reward</strong>: If Person A's investment is less than Person B's investment,
                            Person A will receive a 5% reward based on Person B's investment.
                        </li>
                        <li className='mb-2'>
                            <strong>10% Referral Reward</strong>: If Person A's investment is equal to or greater than Person
                            B's investment, Person A will receive a 10% reward based on Person B's investment.
                        </li>
                    </ul>

                    <h4 style={{ color: '#6C757D', fontSize: '0.8rem' }} className='mb-1 font-semibold'>3. Investment Definitions</h4>
                    <ul style={{ color: '#6C757D', fontSize: '0.75rem' }} className='mb-2'>
                        <li className='mb-2'>
                            <strong>Person A's Investment</strong> refers to the total amount invested by Person A in their
                            own plans with MCOINS.
                        </li>
                        <li className='mb-2'>
                            <strong>Person B's Investment</strong> refers to the total amount invested by Person B.
                        </li>
                    </ul>

                    <h4 style={{ color: '#6C757D', fontSize: '0.8rem' }} className='mb-1 font-semibold'>4. Referral Reward Calculation</h4>
                    <ul style={{ color: '#6C757D', fontSize: '0.75rem' }} className='mb-2'>
                        <li className='mb-2'>
                            The referral reward will be based on Person B's investment and the relative size of Person A's
                            investment in comparison. The reward will be a percentage of Person B's daily income.
                        </li>
                    </ul>

                    <h4 style={{ color: '#F59300', fontSize: '0.8rem' }} className='mb-1 font-semibold'>Illustration of Person A Referral Income:</h4>
                    <ul style={{ color: '#6C757D', fontSize: '0.75rem' }} className='mb-2'>
                        <li className='mb-2'>
                            <strong>Example 1</strong>: If Person A invests Rs 2,000 and Person B invests Rs 5,000:
                            <ul className='mt-1'>
                                <li>Person A will receive 5% of Person B's Daily Bonus: Rs 75.</li>
                            </ul>
                        </li>
                        <li className='mb-2'>
                            <strong>Example 2</strong>: If Person A invests Rs 6,000 and Person B invests Rs 5,000:
                            <ul className='mt-1'>
                                <li>Person A will receive 10% of Person B's Daily Bonus: Rs 150.</li>
                            </ul>
                        </li>
                        <li className='mb-2'>
                            <strong>Example 3</strong>: If Person A invests Rs 6,000 and Person B tops up with Rs 9,000
                            (total Rs 14,000):
                            <ul className='mt-1'>
                                <li>
                                    Since Person A's investment is less than 50% of Person B's total investment, Person A will receive 5% of the first plan's Daily Bonus: Rs 75.
                                </li>
                            </ul>
                        </li>
                        <li className='mb-2'>
                            <strong>Example 4</strong>: If Person A tops up to Rs 10,000 and Person B’s investment is Rs 14,000:
                            <ul className='mt-1'>
                                <li>Person A will receive 10% of the first plan's Daily Bonus: Rs 150.</li>
                            </ul>
                        </li>
                    </ul>

                    <h4 style={{ color: '#6C757D', fontSize: '0.8rem' }} className='mb-1 font-semibold'>5. Referral Reward Conditions</h4>
                    <ul style={{ color: '#6C757D', fontSize: '0.75rem' }} className='mb-2'>
                        <li className='mb-2'>The referral reward will be credited to Person A's account after Person B's investment is confirmed and processed.</li>
                        <li className='mb-2'>The reward will be paid according to MCOINS's regular payout schedule.</li>
                    </ul>

                    <h4 style={{ color: '#6C757D', fontSize: '0.8rem' }} className='mb-1 font-semibold'>6. Disqualification</h4>
                    <ul style={{ color: '#6C757D', fontSize: '0.75rem' }} className='mb-2'>
                        <li>
                            MCOINS reserves the right to disqualify any participant for violations including fraudulent activity, providing false information, or general violations of MCOINS terms.
                        </li>
                    </ul>

                    <h4 style={{ color: '#6C757D', fontSize: '0.8rem' }} className='mb-1 font-semibold'>7. Referral Reward Limitations</h4>
                    <ul style={{ color: '#6C757D', fontSize: '0.75rem' }} className='mb-2'>
                        <li className='mb-2'>Referral rewards are non-transferable and cannot be redeemed for cash unless explicitly stated.</li>
                        <li>Rewards may be subject to taxes based on local jurisdiction.</li>
                    </ul>

                    <h4 style={{ color: '#6C757D', fontSize: '0.8rem' }} className='mb-1 font-semibold'>8. Modifications to the Program</h4>
                    <ul style={{ color: '#6C757D', fontSize: '0.75rem' }} className='mb-2'>
                        <li>MCOINS reserves the right to modify or terminate the Referral Program at any time without prior notice.</li>
                    </ul>

                    <h4 style={{ color: '#6C757D', fontSize: '0.8rem' }} className='mb-1 font-semibold'>9. Program Termination</h4>
                    <ul style={{ color: '#6C757D', fontSize: '0.75rem' }} className='mb-2'>
                        <li>Participants may terminate their participation at any time without affecting previously earned rewards.</li>
                    </ul>

                    <h4 style={{ color: '#6C757D', fontSize: '0.8rem' }} className='mb-1 font-semibold'>10. Limitation of Liability</h4>
                    <ul style={{ color: '#6C757D', fontSize: '0.75rem' }} className='mb-2'>
                        <li>MCOINS is not liable for indirect damages arising from participation in the program.</li>
                    </ul>

                    <h4 style={{ color: '#6C757D', fontSize: '0.8rem' }} className='mb-1 font-semibold'>11. Acceptance of Terms</h4>
                    <ul style={{ color: '#6C757D', fontSize: '0.75rem' }} className='mb-2'>
                        <li>Participation in the Referral Program implies acceptance of all terms.</li>
                    </ul>

                    <p style={{ color: '#6C757D', fontSize: '0.8rem' }} className='mb-28 font-medium'>
                        For any questions or concerns, contact customer support at <a href="mailto:help.mcoins@protonmail.com" style={{ color: '#0066F6' }}>help.mcoins@protonmail.com</a>.
                    </p>
                </div>
            </div>
        </div>
    );
}

export default KnowAboutReferral;
