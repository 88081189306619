import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faIndianRupeeSign, faAngleRight } from '@fortawesome/free-solid-svg-icons';
import './index.css';
import Spinner from '../Loader/Spinner';

const UserProfile = ({ API_URL, user, handleLogout, setIsVerified }) => {
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        if (!user) {
            setLoading(true);
        } else {
            setLoading(false);
        }
    }, [user]);

    const formatCurrency = (value) => {
        const numValue = Number(value);
        if (isNaN(numValue)) {
            return '0.00';
        }
        return numValue.toLocaleString('en-IN', { minimumFractionDigits: 2 });
    };

    const handlePlan = () => {
        navigate('/your-plans/id=profile');
    };

    const handleRefer = () => {
        navigate('/refer-n-earn/id=profile');
    }

    const handlePlans = () => {
        navigate('/know-your-plans');
    }

    const logout = () => {
        setIsVerified(false);
        handleLogout();
    }

    const holdingValue = user?.totalBonus || 0;

    return (
        <div className='portfolio-wrap pb-8'>
            {
                loading ? (
                    <div><Spinner /></div>
                ) : (
                    <>
                        <div className='profile-banner'>
                            <div className='flex flex-wrap flex-col items-center'>
                                <div className='profile-logo'>
                                    <img src={`${user?.photo}`} alt="" className='mt-4 mb-4' />
                                </div>
                                <div className='text-white u-fname'>{user?.fullName}</div>
                                <div className='text-white u-fno'>{user?.mobile}</div>
                            </div>
                            <div className='text-white flex flex-wrap flex-row items-center justify-evenly mt-3' style={{ fontSize: '0.9rem' }}>
                                <div className='flex flex-wrap flex-col items-end'>
                                    <div>Bank Name : </div>
                                    <div>Account No : </div>
                                    <div>IFSC Code : </div>
                                    <div>UPI ID : </div>
                                    <div>Referral Code : </div>
                                    <div>Referred By : </div>
                                </div>
                                <div className='flex flex-wrap flex-col items-baseline'>
                                    <div>{(user?.bankName).toUpperCase()}</div>
                                    <div>{user?.accountNumber}</div>
                                    <div>{user?.ifscCode}</div>
                                    <div>{user?.upiId}</div>
                                    <div>{user?.referalCode}</div>
                                    <div>{user?.referedBy || 'N/A'}</div>

                                </div>
                            </div>
                            <hr className='mt-4 mb-4' />
                            <div className='text-white flex flex-wrap flex-row justify-around'>
                                <div>
                                    <div>Earnings</div>
                                    <div className='text-center'>{formatCurrency(holdingValue)}</div>
                                </div>
                                {/* <div>
                                    <div>Active Referal</div>
                                    <div className='text-center'>0</div>
                                </div> */}
                                <div className='mb-4'>
                                    <div>Referral Bonus</div>
                                    <div className='text-center'>{formatCurrency(user?.totalReferralBonus)}</div>
                                </div>
                            </div>
                        </div>
                        <div className='flex flex-wrap flex-row items-center justify-between mt-8 mb-6' onClick={() => navigate('/privacy-policy')}>
                            <div className='flex flex-wrap flex-row items-center'>
                                <span><img src='/icons-clock-right.png' className='th-img' /></span>
                                <span className='th-text ml-3'>Privacy</span>
                            </div>
                            <div style={{ color: '#999999' }}>
                                <FontAwesomeIcon icon={faAngleRight} />
                            </div>
                        </div>
                        <hr />
                        <div className='flex flex-wrap flex-row items-center justify-between mt-8 mb-4' onClick={handlePlan}>
                            <div className='flex flex-wrap flex-row items-center'>
                                <span><img src='/icons8-grid-2.png' className='th-img' /></span>
                                <span className='th-text ml-3'>Plan Details</span>
                            </div>
                            <div style={{ color: '#999999' }}>
                                <FontAwesomeIcon icon={faAngleRight} />
                            </div>
                        </div>
                        <hr />
                        <div className='flex flex-wrap flex-row items-center justify-between mt-8 mb-4' onClick={handlePlans}>
                            <div className='flex flex-wrap flex-row items-center'>
                                <span><img src='/know-plans.png' className='th-img' /></span>
                                <span className='th-text ml-3'>Know your plans</span>
                            </div>
                            <div style={{ color: '#999999' }}>
                                <FontAwesomeIcon icon={faAngleRight} />
                            </div>
                        </div>
                        <hr />
                        <div className='flex flex-wrap flex-row items-center justify-between mt-8 mb-4' onClick={handleRefer}>
                            <div className='flex flex-wrap flex-row items-center'>
                                <span><img src='/refer-n-earn.png' className='th-img' /></span>
                                <span className='th-text ml-3'>Refer and Earn</span>
                            </div>
                            <div style={{ color: '#999999' }}>
                                <FontAwesomeIcon icon={faAngleRight} />
                            </div>
                        </div>
                        <hr />
                        <div className='flex flex-wrap flex-row items-center justify-between mt-8 mb-4' onClick={() => navigate('/help-and-support')}>
                            <div className='flex flex-wrap flex-row items-center'>
                                <span><img src='/support.png' className='th-img' /></span>
                                <span className='th-text ml-3'>Help and Support</span>
                            </div>
                            <div style={{ color: '#999999' }}>
                                <FontAwesomeIcon icon={faAngleRight} />
                            </div>
                        </div>
                        <hr />
                        <div className='flex flex-wrap flex-row items-center justify-between mt-8 mb-4' onClick={() => navigate('/terms-and-conditions')}>
                            <div className='flex flex-wrap flex-row items-center'>
                                <span><img src='/terms-n-conditions.png' className='th-img' /></span>
                                <span className='th-text ml-3'>Terms and Conditions</span>
                            </div>
                            <div style={{ color: '#999999' }}>
                                <FontAwesomeIcon icon={faAngleRight} />
                            </div>
                        </div>
                        <hr />
                        <div className='flex flex-wrap flex-row items-center justify-between mt-8 mb-4' onClick={logout}>
                            <div className='flex flex-wrap flex-row items-center'>
                                <span><img src='/logout.png' className='th-img' /></span>
                                <span className='th-text ml-3'>Logout</span>
                            </div>
                            <div style={{ color: '#999999' }}>
                                <FontAwesomeIcon icon={faAngleRight} />
                            </div>
                        </div>
                        <hr />
                    </>
                )
            }
        </div>
    );
};

export default UserProfile;
