import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHome, faGift, faPieChart, faChartLine, faUser } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';
import './Footer.css';

const Footer = () => {
    return (
        <footer className='footer-container'>
            <div className='footer-nav mb-4 mt-4'>
                <div>
                    <Link to='/' className='flex justify-center flex-col'>
                        <FontAwesomeIcon icon={faHome} />
                        <div>Home</div>
                    </ Link>
                </div>
                <div>
                    <Link to='/portfolio' className='flex justify-center flex-col'>
                        <FontAwesomeIcon icon={faPieChart} />
                        <div>Portfolio</div>
                    </ Link>
                </div>
                <div>
                    <Link to='/rewards' className='flex justify-center flex-col'>
                        <FontAwesomeIcon icon={faGift} />
                        <div>Rewards</div>
                    </Link>
                </div>
                <div>
                    <Link to='/market' className='flex justify-center flex-col'>
                        <FontAwesomeIcon icon={faChartLine} />
                        <div>Market</div>
                    </Link>
                </div>
                <div>
                    <Link to='/profile' className='flex justify-center flex-col'>
                        <FontAwesomeIcon icon={faUser} />
                        <div>Profile</div>
                    </Link>
                </div>
            </div>
        </footer>
    );
};

export default Footer;
