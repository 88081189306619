import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleLeft } from '@fortawesome/free-solid-svg-icons';
import { Calendar } from 'primereact/calendar';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';

import './index.css';
import Spinner from '../Loader/Spinner';

function TransactionTabs({ API_URL, token }) {
    const [key, setKey] = useState('deposit');
    const navigate = useNavigate();

    const handleNav = () => {
        navigate('/portfolio');
    };

    return (
        <div className='transaction-tabs'>
            <div
                className="mt-6 flex flex-wrap flex-row items-center justify-start gap-4 bread-nav"
                onClick={handleNav}
            >
                <FontAwesomeIcon icon={faAngleLeft} />
                <span className="plan-name">History</span>
            </div>
            <Tabs
                id="fill-tab-example"
                activeKey={key}
                onSelect={(k) => setKey(k)}
                className="mb-3 flex flex-wrap flex-row justify-between"
                fill
            >
                <Tab eventKey="deposit" title="Deposit">
                    {key === "deposit" && <TransactionHistory API_URL={API_URL} token={token} />}
                </Tab>
                <Tab eventKey="withdrawal" title="Withdrawal">
                    {key === "withdrawal" && <Withdrawal API_URL={API_URL} token={token} />}
                </Tab>
            </Tabs>
        </div>
    );
}

const TransactionHistory = ({ API_URL, token }) => {
    const [loading, setLoading] = useState(false);
    const [fromDate, setFromDate] = useState(null);
    const [toDate, setToDate] = useState(null);
    const [plans, setPlans] = useState([]);
    const [allPlans, setAllPlans] = useState([]); // Store all plans

    const navigate = useNavigate();
    const handleNav = () => {
        navigate('/portfolio');
    };

    useEffect(() => {
        setLoading(true);
        const getPlans = async () => {
            try {
                const config = {
                    headers: {
                        Authorization: `Bearer ${token}`,
                        'Content-Type': 'multipart/form-data',
                    },
                };

                const response = await axios.get(`${API_URL}/api/plan/user-plans`, config);
                if (response.status === 200) {
                    setLoading(false);
                    setPlans(response.data.plans); // Set the initial state
                    setAllPlans(response.data.plans); // Store all plans
                }
            } catch (error) {
                console.error(error);
                setLoading(false);
            }
        };

        getPlans();
    }, [API_URL, token]);

    const formatDate = (date) => {
        if (!date) return null;
        const d = new Date(date);
        const year = d.getFullYear();
        const month = String(d.getMonth() + 1).padStart(2, '0');
        const day = String(d.getDate()).padStart(2, '0');
        return `${year}-${month}-${day}`;
    };

    const fetchPlans = async (from, to) => {
        setLoading(true);
        try {
            const config = {
                headers: {
                    Authorization: `Bearer ${token}`,
                    'Content-Type': 'multipart/form-data',
                },
            };

            const response = await axios.get(`${API_URL}/api/plan/user-plans/filter?fromDate=${from}&toDate=${to}`, config);
            if (response.status === 200) {
                setLoading(false);
                setPlans(response.data.plans);
            }
        } catch (error) {
            console.error(error);
            setPlans([]);
            setLoading(false);
        }
    };

    const handleFilter = () => {
        const formattedFromDate = formatDate(fromDate);
        const formattedToDate = formatDate(toDate);
        if (formattedFromDate && formattedToDate) {
            fetchPlans(formattedFromDate, formattedToDate);
        } else {
            alert("Please select both from and to dates.");
            setPlans(allPlans); // Reset to all plans if no date range is selected
        }
    };

    const formatDateTime = (dateString) => {
        const options = {
            day: '2-digit',
            month: 'short',
            year: 'numeric',
            hour12: true,
        };
        return new Intl.DateTimeFormat('en-GB', options).format(new Date(dateString));
    };

    const formatTime = (dateString) => {
        const options = {
            hour: '2-digit',
            minute: '2-digit',
            hour12: true,
        };
        return new Intl.DateTimeFormat('en-GB', options).format(new Date(dateString));
    };

    const showAllTransactions = () => {
        setFromDate(null);
        setToDate(null);
        setPlans(allPlans);
    };

    const clearFilter = () => {
        setFromDate(null);
        setToDate(null);
        setPlans(allPlans);
    }

    return (
        <div className='portfolio-wrap'>
            {/* <div className='mt-6 flex flex-wrap flex-row items-center justify-start gap-4 bread-nav' onClick={handleNav}>
                <FontAwesomeIcon icon={faAngleLeft} />
                <span className='plan-name'>History</span>
            </div> */}

            {loading ? (
                <div>
                    <Spinner />
                </div>
            ) : (
                <>
                    <div className='flex flex-wrap flex-row items-center filter-wrap'>
                        <div className={`filter-0 ${fromDate ? 'selected' : ''}`}>
                            <CalendarIcon label='From' date={fromDate} setDate={setFromDate} />
                        </div>
                        <div className={`filter-1 ${toDate ? 'selected' : ''}`}>
                            <CalendarIcon label='To' date={toDate} setDate={setToDate} />
                        </div>
                    </div>
                    <div className='flex flex-wrap flex-row justify-around'>
                        <div className='mt-4 filter-btn' onClick={handleFilter}>
                            <button>Apply Filter</button>
                        </div>
                        <div className='mt-4 filter-btn' onClick={clearFilter}>
                            <button>Clear Filter</button>
                        </div>
                    </div>
                    <div className='mt-6'>
                        {plans.length === 0 ? (
                            <>
                                <div style={{ color: '#26273A', fontSize: '1.2rem', textAlign: 'center' }} className='mt-28'>
                                    No results found.
                                </div>
                                <div style={{ color: '#5DC486', fontSize: '1.2rem', textAlign: 'center', textDecoration: 'underline' }} className='mt-10' onClick={showAllTransactions}>View all transactions</div>
                            </>
                        ) : (
                            plans.map((plan, id) => (
                                <div className='flex flex-wrap flex-row justify-between plan mb-5 gap-4' key={id}>
                                    <div className='flex flex-wrap flex-row items-center justify-between gap-8'>
                                        <div>
                                            <img src="/wallet.png" alt="" />
                                        </div>
                                        <div className='flex flex-wrap flex-col gap-1'>
                                            <span style={{ color: '#26273A', fontSize: '1.2rem' }}>Deposit</span>
                                            <span style={{ color: '#26273A', fontSize: '1rem' }}>To Mcoin</span>
                                            <span style={{ color: '#26273A99', fontSize: '0.8rem' }}>Transaction ID</span>
                                            <span style={{ color: '#26273A99', fontSize: '0.8rem' }}>{plan?._id.length > 12 ? `${plan._id.slice(0, 12)}...` : plan._id}</span>
                                        </div>
                                    </div>
                                    <div className='flex flex-wrap flex-col gap-1'>
                                        <span style={{ color: '#26273A', fontSize: '1.2rem' }}>{plan?.purchasedAmount}.00</span>
                                        <div
                                            style={{
                                                backgroundColor:
                                                    plan?.status === 'pending' ? '#F7931A' :
                                                        plan?.status === 'accepted' ? '#21BF73' :
                                                            plan?.status === 'rejected' ? '#D90429' :
                                                                '#5DC486', // Default color if status is undefined or unrecognized
                                                color: '#fff',
                                                borderRadius: '0.5rem',
                                                textAlign: 'center'
                                            }}
                                        >
                                            {plan?.status}
                                        </div>
                                        <span style={{ color: '#26273A99', fontSize: '0.9rem' }}>
                                            {formatDateTime(plan?.createdAt)},<br />
                                            {formatTime(plan?.createdAt)}
                                        </span>
                                    </div>

                                </div>
                            ))
                        )}
                    </div>
                </>
            )}
        </div >
    );
};

function CalendarIcon({ label, date, setDate }) {
    return (
        <div className="card flex flex-wrap gap-3 p-fluid">
            <div className="flex flex-auto flex-row justify-center items-baseline">
                <label htmlFor="buttondisplay" className="font-bold block mb-2 ml-2"></label>
                <Calendar placeholder={label} value={date} onChange={(e) => setDate(e.value)} dateFormat="dd/mm/yy" showIcon />
            </div>
        </div>
    );
};

const Withdrawal = ({ API_URL, token }) => {

    const [loading, setLoading] = useState(false);
    const [fromDate, setFromDate] = useState(null);
    const [toDate, setToDate] = useState(null);
    const [plans, setPlans] = useState([]);
    const [allPlans, setAllPlans] = useState([]); // Store all plans

    const navigate = useNavigate();
    const handleNav = () => {
        navigate('/portfolio');
    };

    useEffect(() => {

        setLoading(true);
        const getPlans = async () => {
            try {
                const config = {
                    headers: {
                        Authorization: `Bearer ${token}`,
                        'Content-Type': 'multipart/form-data',
                    },
                };

                const response = await axios.get(`${API_URL}/api/profile/withdrawal-history`, config);
                if (response.status === 200) {
                    setLoading(false);
                    setPlans(response.data.plans); // Set the initial state
                    setAllPlans(response.data.plans); // Store all plans
                }
            } catch (error) {
                console.error(error);
                setLoading(false);
            }
        };

        getPlans();
    }, [API_URL, token]);

    const formatDate = (date) => {
        if (!date) return null;
        const d = new Date(date);
        const year = d.getFullYear();
        const month = String(d.getMonth() + 1).padStart(2, '0');
        const day = String(d.getDate()).padStart(2, '0');
        return `${year}-${month}-${day}`;
    };

    const fetchPlans = async (from, to) => {
        setLoading(true);
        try {
            const config = {
                headers: {
                    Authorization: `Bearer ${token}`,
                    'Content-Type': 'multipart/form-data',
                },
            };

            const response = await axios.get(`${API_URL}/api/profile/filter-withdrawal?fromDate=${from}&toDate=${to}`, config);
            if (response.status === 200) {
                setLoading(false);
                setPlans(response.data.plans);
            }
        } catch (error) {
            console.error(error);
            setPlans([]);
            setLoading(false);
        }
    };

    const handleFilter = () => {
        const formattedFromDate = formatDate(fromDate);
        const formattedToDate = formatDate(toDate);
        if (formattedFromDate && formattedToDate) {
            fetchPlans(formattedFromDate, formattedToDate);
        } else {
            alert("Please select both from and to dates.");
            setPlans(allPlans); // Reset to all plans if no date range is selected
        }
    };

    const formatDateTime = (dateString) => {
        const options = {
            day: '2-digit',
            month: 'short',
            year: 'numeric',
            hour12: true,
        };
        return new Intl.DateTimeFormat('en-GB', options).format(new Date(dateString));
    };

    const formatTime = (dateString) => {
        const options = {
            hour: '2-digit',
            minute: '2-digit',
            hour12: true,
        };
        return new Intl.DateTimeFormat('en-GB', options).format(new Date(dateString));
    };

    const showAllTransactions = () => {
        setFromDate(null);
        setToDate(null);
        setPlans(allPlans);
    };

    const clearFilter = () => {
        setFromDate(null);
        setToDate(null);
        setPlans(allPlans);
    }

    return (
        <div className='portfolio-wrap'>
            {/* <div className='mt-6 flex flex-wrap flex-row items-center justify-start gap-4 bread-nav' onClick={handleNav}>
                <FontAwesomeIcon icon={faAngleLeft} />
                <span className='plan-name'>History</span>
            </div> */}

            {loading ? (
                <div>
                    <Spinner />
                </div>
            ) : (
                <>
                    <div className='flex flex-wrap flex-row items-center filter-wrap'>
                        <div className={`filter-0 ${fromDate ? 'selected' : ''}`}>
                            <CalendarIcon label='From' date={fromDate} setDate={setFromDate} />
                        </div>
                        <div className={`filter-1 ${toDate ? 'selected' : ''}`}>
                            <CalendarIcon label='To' date={toDate} setDate={setToDate} />
                        </div>
                    </div>
                    <div className='flex flex-wrap flex-row justify-around'>
                        <div className='mt-4 filter-btn' onClick={handleFilter}>
                            <button>Apply Filter</button>
                        </div>
                        <div className='mt-4 filter-btn' onClick={clearFilter}>
                            <button>Clear Filter</button>
                        </div>
                    </div>
                    <div className='mt-6'>
                        {plans.length === 0 ? (
                            <>
                                <div style={{ color: '#26273A', fontSize: '1.2rem', textAlign: 'center' }} className='mt-28'>
                                    No results found.
                                </div>
                                <div style={{ color: '#5DC486', fontSize: '1.2rem', textAlign: 'center', textDecoration: 'underline' }} className='mt-10' onClick={showAllTransactions}>View all transactions</div>
                            </>
                        ) : (
                            plans.map((plan, id) => (
                                <div className='flex flex-wrap flex-row justify-between plan mb-5 gap-4' key={id}>
                                    <div className='flex flex-wrap flex-row items-center justify-between gap-8'>
                                        <div>
                                            <img src="/withdrawal.png" alt="" />
                                        </div>
                                        <div className='flex flex-wrap flex-col gap-1'>
                                            <span style={{ color: '#26273A', fontSize: '1.2rem' }}>Withdrawal</span>
                                            <span style={{ color: '#26273A', fontSize: '1rem' }}>From Mcoin</span>
                                            <span style={{ color: '#26273A99', fontSize: '0.8rem' }}>Transaction ID</span>
                                            <span style={{ color: '#26273A99', fontSize: '0.8rem' }}>{plan?._id.length > 12 ? `${plan._id.slice(0, 12)}...` : plan._id}</span>
                                        </div>
                                    </div>
                                    <div className='flex flex-wrap flex-col gap-1'>
                                        <span style={{ color: '#26273A', fontSize: '1.2rem' }}>{plan?.amount}.00</span>
                                        <div
                                            style={{
                                                backgroundColor:
                                                    plan?.status === 'pending' ? '#F7931A' :
                                                        plan?.status === 'accepted' ? '#21BF73' :
                                                            plan?.status === 'rejected' ? '#D90429' :
                                                                '#5DC486', // Default color if status is undefined or unrecognized
                                                color: '#fff',
                                                borderRadius: '0.5rem',
                                                textAlign: 'center'
                                            }}
                                        >
                                            {plan?.status}
                                        </div>
                                        <span style={{ color: '#26273A99', fontSize: '0.9rem' }}>
                                            {formatDateTime(plan?.createdAt)},<br />
                                            {formatTime(plan?.createdAt)}
                                        </span>
                                    </div>

                                </div>
                            ))
                        )}
                    </div>
                </>
            )}
        </div >
    );
};

export default TransactionTabs;
