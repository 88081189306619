import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleLeft, faIndianRupeeSign } from '@fortawesome/free-solid-svg-icons';
import Swal from 'sweetalert2';
import './index.css';
import Spinner from '../Loader/Spinner';

const WithdrawPayment = ({ API_URL, token, trPin, user }) => {
    const [planPrice, setPlanPrice] = useState('');
    const [amount, setAmount] = useState(0);  // Track the entered amount
    const [errorMessage, setErrorMessage] = useState('');  // For displaying validation messages
    const [showContent, setShowContent] = useState(false);
    const [tPin, setTpin] = useState('');
    const [showVerifyTpin, setShowVerifyTpin] = useState(false);
    const [isLoading, setIsLoding] = useState(false);
    const [paymentCalculation, setPaymentCalculation] = useState(null);
    const [paymentDetailsContent, showPaymentDetailsContent] = useState(false);
    const navigate = useNavigate();

    const [windowHeight, setWindowHeight] = useState(window.innerHeight);

    useEffect(() => {
        const handleResize = () => {
            setWindowHeight(window.innerHeight);
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    useEffect(() => {
        const verifyTpin = localStorage.getItem('showVerifyTpin');
        if (trPin || (verifyTpin == 'true')) {
            setShowVerifyTpin(true);
        } else {
            setShowVerifyTpin(false);
        }
    }, [showVerifyTpin])

    const handleAmountChange = (e) => {
        const enteredAmount = parseInt(e.target.value, 10);

        if (isNaN(enteredAmount) || enteredAmount < 0) {
            setErrorMessage('Invalid amount.');
            setAmount(0); // Optionally reset amount to 0 or keep it undefined
            return;
        }

        // if (enteredAmount % 1000 !== 0) {
        //     setErrorMessage('Amount should be in multiples of 1000');
        // } else {
        //     setErrorMessage('');
        // }

        setAmount(enteredAmount);
        showPaymentDetailsContent(false);
        setErrorMessage('');
    }

    const handleNav = () => {
        navigate('/portfolio');
    }

    const handleTpinChange = (e) => {
        if (e.target.value.length > 5) {
            setErrorMessage('Transaction Pin should be in 5 digits');
        }
        if (!e.target.value) {
            setErrorMessage('');
        }
        setTpin(e.target.value);
    }

    const handleCreateTpin = async (e) => {
        e.preventDefault();

        if (!tPin) {
            setErrorMessage('Enter a 5 digit Tpin');
            return;
        }

        try {
            setIsLoding(true);
            const config = {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            };

            const res = await axios.post(`${API_URL}/api/auth/create-tpin`, { tPin }, config);

            if (res.data?.user?.tPin) {
                localStorage.setItem('showVerifyTpin', true);
                setShowContent(true);
                setErrorMessage('');
                setIsLoding(false);
            }
        } catch (error) {
            setShowContent(false);
            setErrorMessage(error.response?.data?.error || 'Failed to create Tpin');
            setIsLoding(false);
        }
    }

    const handleVerifyTpin = async (e) => {
        e.preventDefault();

        if (!tPin) {
            setErrorMessage('Enter a 5 digit Tpin');
            return;
        }

        try {
            setIsLoding(true);
            const config = {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            };

            const res = await axios.post(`${API_URL}/api/auth/verify-tpin`, { tPin }, config);
            if (res.data?.user?.tPin) {
                setShowContent(true);
                setErrorMessage('');
                setIsLoding(false);
            }
        } catch (error) {
            if (!trPin) {
                localStorage.removeItem('showVerifyTpin');
            }
            setShowContent(false);
            setErrorMessage(error.response?.data?.error || 'Tpin verification failed.');
            setIsLoding(false);
            navigate('/withdraw');
        }
    }

    const withdrawPayment = async (e) => {
        e.preventDefault();
        if (!amount) {
            setErrorMessage('Enter digit amount');
            return;
        }
        try {
            setIsLoding(true);
            const config = {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            };
            const res = await axios.post(`${API_URL}/api/profile/withdraw-payment`, { amount }, config);
            if (res.data?.user) {
                setIsLoding(false);
                setShowContent(true);
                setErrorMessage('');
                navigate('/portfolio');
            }
        } catch (error) {
            setIsLoding(false);
            setErrorMessage(error.response?.data?.message || error.response?.data?.error || 'Something went wrong');
        }
    }

    const paymentDetails = async (e) => {
        e.preventDefault();
        if (!amount) {
            setErrorMessage('Enter digit amount');
            return;
        }
        try {
            setIsLoding(true);
            const config = {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            };
            const res = await axios.post(`${API_URL}/api/profile/withdraw-payment-details`, { amount }, config);
            console.log(res);

            if (res.data?.finalWithdrawAmount && res.data?.processingFee && res.data?.taxDeduction) {
                setPaymentCalculation({
                    finalWithdrawAmount: res.data?.finalWithdrawAmount,
                    processingFee: res.data?.processingFee,
                    taxDeduction: res.data?.taxDeduction
                });
                showPaymentDetailsContent(true);
                setIsLoding(false);
                // setShowContent(true);
                setErrorMessage('');
                // navigate('/portfolio');
            }
        } catch (error) {
            setIsLoding(false);
            setErrorMessage(error.response?.data?.message || error.response?.data?.error || 'Something went wrong');
        }
    }

    const handleRaiseRequest = async () => {
        try {
            setIsLoding(true);
            const config = {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            };
            const res = await axios.post(`${API_URL}/api/profile/withdraw-payment-request`, { amount }, config);

            if (Object.keys(res.data?.withdrawal).length > 0) {
                let timerInterval;

                Swal.fire({
                    icon: 'success',
                    title: 'Payment request raised',
                    text: 'Amount will be reflected in your account in 48 to 72 hrs, Keep checking your account.',
                    confirmButtonColor: '#F7931A',
                    allowOutsideClick: false,
                }).then(() => {
                    Swal.fire({
                        title: 'Redirecting...',
                        html: 'Wait for <b></b> seconds.',
                        timer: 4000,
                        timerProgressBar: true,
                        showConfirmButton: false,
                        allowOutsideClick: false,
                        willOpen: () => {
                            Swal.showLoading();
                            const b = Swal.getHtmlContainer().querySelector('b');
                            timerInterval = setInterval(() => {
                                b.textContent = Math.ceil(Swal.getTimerLeft() / 1000);
                            }, 100);
                        },
                        willClose: () => {
                            clearInterval(timerInterval);
                        }
                    }).then(() => {
                        navigate(`/portfolio`);
                    });
                });
                // Show loader with a countdown timer for 4 seconds
            }
        } catch (error) {
            setIsLoding(false);
            setErrorMessage(error.response?.data?.message || error.response?.data?.error || 'Something went wrong');
        }
    }

    if (isLoading) {
        <Spinner />;
    }

    const handleTopUp = () => {
        navigate('/our-plans');
    }

    const formatCurrency = (value) => {
        const numValue = Number(value); // Convert value to a number
        if (isNaN(numValue)) {
            return '0.00'; // Fallback if value is not a number
        }
        return numValue.toLocaleString('en-IN', { minimumFractionDigits: 2 });
    };

    const formattedHoldingValue = formatCurrency(parseFloat(user?.totalBonus || 0));
    const holdingValue = parseFloat(user?.totalDailyBonus || 0) + parseFloat(user?.totalReferralBonus || 0);

    return (
        <div className='withdraw-payment-container' style={{ margin: windowHeight < 700 ? '1rem auto 6rem auto' : '1rem auto auto auto' }}>

            <div className='mt-6 flex flex-wrap flex-row items-center justify-between gap-4 bread-nav' onClick={handleNav}>
                <div className='flex flex-wrap flex-row items-center justify-center'>
                    <FontAwesomeIcon icon={faAngleLeft} />
                    <div className='ml-4'>
                        Back
                    </div>
                </div>
            </div>
            {
                !showContent && (
                    <>
                        {
                            showVerifyTpin ? (
                                <>
                                    <h2 className='mt-10 text-center'>Enter Transaction Pin</h2>
                                    <form onSubmit={handleVerifyTpin} className='flex flex-col justify-center items-center gap-1 mt-4 mb-8'>
                                        <input
                                            type="text"
                                            name='tPin'
                                            value={tPin}
                                            onChange={handleTpinChange}
                                            maxLength={5}
                                            className='mobile-number-input'
                                        />
                                        {errorMessage && <p className='mt-4 text-center text-red-600'>{errorMessage}</p>}
                                        <button type="submit" className='primary-button primary-btn mt-4' disabled={!tPin}>Verify</button>
                                    </form>
                                </>
                            ) : (
                                <>
                                    <h2 className='mt-10 text-center'>Create Your Transaction Pin</h2>
                                    <form onSubmit={handleCreateTpin} className='flex flex-col justify-center items-center gap-1 mt-4 mb-8'>
                                        <input
                                            type="text"
                                            name='tPin'
                                            value={tPin}
                                            onChange={handleTpinChange}
                                            maxLength={5}
                                            minLength={5}
                                            className='mobile-number-input'
                                        />
                                        {errorMessage && <p className='mt-4 text-center text-red-600'>{errorMessage}</p>}
                                        <button type="submit" className='primary-button primary-btn mt-4' disabled={!tPin}>Create</button>
                                    </form>
                                </>
                            )
                        }
                    </>
                )
            }
            {
                showContent && (
                    <>
                        <div className='text-center' style={{ fontSize: '0.9rem', color: '#333' }}>
                            <span>Wallet amount : </span>
                            <span>{formattedHoldingValue}</span>
                        </div>
                        <div className='flex flex-wrap flex-row items-center justify-center mt-6'>
                            <span style={{ color: '#999999', fontSize: '0.8rem' }}>Enter amount in INR</span>
                            <div style={{ color: '#212529', fontSize: '1.5rem' }} className='amount-wrapper mt-4 flex flex-wrap flex-row items-center justify-center'>
                                <span>
                                    <FontAwesomeIcon icon={faIndianRupeeSign} />
                                </span>
                                <input
                                    type='number'
                                    inputMode='numeric'
                                    pattern="[0-9]*"
                                    placeholder='0'
                                    className='amount-input ml-1'
                                    value={amount || ''}
                                    onChange={handleAmountChange}
                                />
                            </div>
                            {errorMessage && <div style={{ color: 'red', marginTop: '10px', textAlign: 'center' }}>{errorMessage}</div>}
                        </div>
                        {/* <div className='mt-12' style={{ color: '#343A40' }}>
                            <div>* Withdrawal amount should be in multiple of <span><FontAwesomeIcon icon={faIndianRupeeSign} /></span>1000</div>
                        </div> */}
                        {
                            !paymentDetailsContent && (
                                <div className='deposit-btn db-bg mt-6' style={{ backgroundColor: (amount === 0 || errorMessage) ? '#D3D3D3' : "#0063F5" }} onClick={paymentDetails} >
                                    <button>Next</button>
                                </div>

                            )
                        }
                    </>
                )
            }
            {
                paymentDetailsContent && (
                    <PaymentDetails paymentCalculation={paymentCalculation} handleRaiseRequest={handleRaiseRequest} handleTopUp={handleTopUp} />
                )
            }
        </div>
    );
};

const PaymentDetails = ({ paymentCalculation, handleRaiseRequest, handleTopUp }) => {
    const formatCurrency = (value) => {
        const numValue = Number(value); // Convert value to a number
        if (isNaN(numValue)) {
            return '0.00'; // Fallback if value is not a number
        }
        return numValue.toLocaleString('en-IN', { minimumFractionDigits: 2 });
    };

    return (
        <>
            <h2 className='mt-10 text-center font-medium mb-4' style={{ fontSize: '1.1rem', letterSpacing: '1px' }}>Transaction details</h2>
            <div className='flex flex-wrap flex-row justify-between'>
                <div className='flex flex-wrap flex-col gap-4'>
                    <div>Processing Fee : </div>
                    <div>Tax Deduction : </div>
                    <div>Withdrawable Amount : </div>
                </div>
                <div className='flex flex-wrap flex-col gap-4'>
                    <div>{formatCurrency(paymentCalculation?.processingFee || 0)}</div>
                    <div>{formatCurrency(paymentCalculation?.taxDeduction || 0)}</div>
                    <div>{formatCurrency(paymentCalculation?.finalWithdrawAmount || 0)}</div>
                </div>
            </div>
            <hr className='mt-6' />
            <div>
                <div className='deposit-btn db-bg mt-4' style={{ backgroundColor: paymentCalculation.finalWithdrawAmount ? '#0063F5' : '#D3D3D3' }} onClick={handleRaiseRequest}>
                    <button>Raise withdraw request</button>
                </div>
                <div className='flex flex-col flex-wrap mt-4 justify-center items-center'>
                    <div className='mb-4 font-semibold' style={{ fontSize: '1.2rem' }}>OR</div>
                    <div style={{ fontSize: '0.9rem', color: '#333' }} className='flex flex-wrap flex-row justify-between gap-4'>
                        <div className='wallet-img'>
                            <img src="/gold-bar_9368075.png" alt="wallet" />
                        </div>
                        <span>
                            Invest this amount to get more bonus <br /> and rewards.
                        </span>
                    </div>
                    <div className='mt-6 deposit-btn' style={{ backgroundColor: '#0063F5' }} onClick={handleTopUp}>
                        <span style={{ fontSize: '1rem' }}>Top-up your plan using Mcoin wallet</span>
                    </div>
                </div>
            </div>
        </>
    )
}

export default WithdrawPayment;
