import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom'
import axios from 'axios';
import './Rewards.css';
import Spinner from '../Loader/Spinner';

const Rewards = ({ API_URL, token }) => {
    const [isLoading, setIsLoading] = useState(false);
    const [data, setData] = useState(null);
    const navigate = useNavigate();

    useEffect(() => {
        const fetchData = async () => {
            setIsLoading(true);
            try {
                const config = {
                    headers: {
                        Authorization: `Bearer ${token}`,
                        'Content-Type': 'multipart/form-data',
                    },
                };

                const response = await axios.get(`${API_URL}/api/plan/referral-details`, config);
                if (response.status === 200) {
                    setData(response?.data);
                }
            } catch (error) {
                console.error(error);
                setIsLoading(false);
            } finally {
                setIsLoading(false);
            }
        };

        fetchData();
    }, [token]);

    const handleReferAndearn = () => {
        navigate('/refer-n-earn');
    }

    if (isLoading) {
        <Spinner />
    }

    return (
        <div className='reward-container flex flex-wrap flex-col justify-center items-center gap-5 mt-6'>
            {/* <div className='wrap'>
                <div className='cr-0'>Coupons</div>
                <div className='flex flex-wrap flex-row justify-between gap-28 mt-4'>
                    <span className='cr-text'>No.of Coupons Won</span><span className='cr-text-2'>0</span>
                </div>
                <div className='flex flex-wrap flex-row justify-between gap-28 mt-3'>
                    <span className='cr-text'>Tokens won from Spin so far</span><span className='cr-text-2'>0</span>
                </div>
                <div className='flex flex-wrap flex-row justify-between gap-28 mt-3'>
                    <span className='cr-text'>Remaining Coupons to Spin</span><span className='cr-text-2'>0</span>
                </div>
            </div> */}
            <div className='wrap'>
                <div className='cr-0'>Referral</div>
                <div className='flex flex-wrap flex-row justify-between gap-24 mt-4'>
                    <span className='cr-text'>Total No of referral</span><span className='cr-text-2'>{data?.totalReferedUsers || 0}</span>
                </div>
                <div className='flex flex-wrap flex-row justify-between gap-24 mt-3'>
                    <span className='cr-text'>Total No of Qualified referral</span><span className='cr-text-2'>{data?.totalQualifiedReferral || 0}</span>
                </div>
            </div>
            <div className='mt-2' onClick={handleReferAndearn}>
                <img src="Group138.png" alt="" />
            </div>
            <div>
                <img src="Group139.png" alt="" />
            </div>
        </div>
    );
};

export default Rewards;
