import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleLeft } from '@fortawesome/free-solid-svg-icons';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import './ReferAndEarn.css';
import Spinner from '../Loader/Spinner';

const ReferAndEarn = ({ refCode, API_URL, token }) => {
    const [copied, setCopied] = useState(false); // State to handle "Link Copied" message
    const [isLoading, setIsLoading] = useState(false);
    const [data, setData] = useState(null);
    const [referralLink, setRefferalLink] = useState('');
    const { id } = useParams(); // Access the 'id' parameter from the route

    const navigate = useNavigate();

    useEffect(() => {
        const fetchData = async () => {
            try {
                const config = {
                    headers: {
                        Authorization: `Bearer ${token}`,
                        'Content-Type': 'multipart/form-data',
                    },
                };

                const response = await axios.get(`${API_URL}/api/auth/referal-link`, config);
                if (response.status === 200) {
                    setRefferalLink(response?.data?.link);
                }
            } catch (error) {
                console.error(error);
            }
        };

        fetchData();
    }, [token])

    useEffect(() => {
        const fetchData = async () => {
            setIsLoading(true);
            try {
                const config = {
                    headers: {
                        Authorization: `Bearer ${token}`,
                        'Content-Type': 'multipart/form-data',
                    },
                };

                const response = await axios.get(`${API_URL}/api/plan/referral-details`, config);
                if (response.status === 200) {
                    setData(response?.data);
                }
            } catch (error) {
                console.error(error);
                setIsLoading(false);
            } finally {
                setIsLoading(false);
            }
        };

        fetchData();
    }, [token]);

    // Function to navigate back to the rewards page
    const handleNav = () => {
        if (id) {
            navigate('/profile');
        } else {
            navigate('/rewards');
        }
    };

    // Function to handle WhatsApp share
    const handleShareOnWhatsApp = () => {
        const message = `Join Mcoins and earn free cryptocurrency and daily bonus! Use my referral code: ${refCode}. Sign up now: ${referralLink}`;
        const whatsappUrl = `https://api.whatsapp.com/send?text=${encodeURIComponent(message)}`;
        window.open(whatsappUrl, '_blank'); // Opens WhatsApp in a new tab/window
    };

    // Function to copy referral link to clipboard
    const handleCopyCode = () => {
        navigator.clipboard.writeText(referralLink).then(() => {
            setCopied(true); // Set copied state to true
            setTimeout(() => {
                setCopied(false); // Hide "Link Copied" message after 2 seconds
            }, 2000);
        }).catch(err => {
            console.error('Failed to copy text: ', err);
        });
    };

    if (isLoading) {
        <Spinner />
    }

    return (
        <>
            <div className='mt-6 flex flex-wrap flex-row items-center justify-start gap-4 bread-nav' onClick={handleNav}>
                <FontAwesomeIcon icon={faAngleLeft} />
                <span>Refer and Earn</span>
            </div>
            <div className='refer-container flex flex-wrap flex-col justify-center items-center gap-5 mt-6'>
                <div className='wrap'>
                    <div className='cr-0'>Referral</div>
                    <div className='flex flex-wrap flex-row justify-between gap-28 mt-4'>
                        <span className='cr-text'>Total No of referral</span><span className='cr-text-2'>{data?.totalReferedUsers || 0}</span>
                    </div>
                    <div className='flex flex-wrap flex-row justify-between gap-28 mt-3'>
                        <span className='cr-text'>Total No of Qualified referral</span><span className='cr-text-2'>{data?.totalQualifiedReferral || 0}</span>
                    </div>
                </div>
                <div className='wrap ml-2 mr-2 rae'>
                    <div className='flex flex-wrap flex-row justify-center items-center'>
                        <img src="Group1.png" alt="" className='mt-4' />
                        <span style={{ color: '#212529', fontSize: '1.1rem' }} className='mt-4'>Refer and Earn Free Cryptocurrency</span>
                        <span className='mt-2' style={{ color: '#6C757D', fontSize: '0.8rem' }}>
                            Introducing <span style={{ letterSpacing: '1px', fontWeight: '600' }}>mcoins</span> Referral. As part of <br />
                            this new program we will be giving away up to <br />
                            100% of our earnings from your referral. And it <br />
                            does not stop there. You get a chance to win <br />
                            up to 10,000 free tokens. Refer, share and <br />
                            Earn.
                        </span>
                    </div>
                    <div className='mt-3' style={{ fontSize: '0.8rem', textAlign: 'center', color: '#0063F5' }} onClick={() => navigate('/know-about-referal')}>
                        Click here to know more about referral and reward
                    </div>
                    <div className='mt-4 pl-4'>
                        <span style={{ color: '#343A40' }}>Your Referral Link</span>
                        <InputGroup className="mb-3 referal-wrap mt-2">
                            <Form.Control
                                placeholder="Referral Link"
                                aria-label="Referral Link"
                                aria-describedby="basic-addon2"
                                disabled
                                value={referralLink}
                                className='mb-2'
                            />
                            <Button variant="outline-secondary" id="button-addon2" onClick={handleCopyCode}>
                                Copy Code
                            </Button>
                        </InputGroup>
                        {copied && <span style={{ color: '#F7931A' }}>Link Copied!</span>} {/* Display "Link Copied" message */}
                    </div>
                    <div className='mt-4 pl-4'>
                        <InputGroup className="mb-3 mt-2 share" onClick={handleShareOnWhatsApp}>
                            <Button variant="outline-secondary" id="button-addon2">
                                Share Now
                            </Button>
                        </InputGroup>
                    </div>
                    <div className='mt-4 mb-3 text-center' style={{ color: '#0063F5', fontSize: '0.8rem' }}>* Terms and Conditions Applied</div>
                </div>
            </div>
        </>
    );
};

export default ReferAndEarn;